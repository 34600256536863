#root>div {
  width: 100%;
}

#root .layout {
  width: 100%;
}

svg {
  min-height: 0 !important;
  background-color: transparent !important;
}

div.MuiTextField-root.RaAutocompleteInput-textField {
  min-width: 300px;
}

div.RaArrayInput-root>label {
  font-size: 34px;
}

label.MuiInputLabel-root.MuiInputLabel-shrink>span {
  font-weight: 500;
}