@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

/* for deprecated FormDataConsumer */
.crowdsales-input .fade-enter {
  opacity: 1 !important;
  transform: translateX(0) !important;
}

.MuiInputLabel-root.MuiInputLabel-shrink > span {
  font-weight: 600;
}
